// components/Chat/OtherUsers.jsx
import React from 'react';
import OtherUser from './OtherUser';
import useGetOtherUsers from '../../hooks/useGetOtherUsers';
import { useSelector } from "react-redux";

const OtherUsers = () => {
    // Use custom hook to fetch other users
    useGetOtherUsers();
    
    // Get other users from the Redux store
    const { otherUsers } = useSelector(store => store.user);
    
    // Early return if there are no other users
    if (!otherUsers) return null; // Return null if no users are found

    return (
        <div className='overflow-hidden flex-1'>
            {otherUsers.length > 0 ? (
                otherUsers.map((user) => (
                    <OtherUser key={user._id} user={user} />
                ))
            ) : (
                <div className="text-center text-gray-500">No users found.</div>
            )}
        </div>
    );
};

export default OtherUsers;
