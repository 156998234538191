import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import { FiEdit, FiTrash, FiPlus } from 'react-icons/fi';

const EventManagement = () => {
  const [events, setEvents] = useState([]);
  const [editingEvent, setEditingEvent] = useState(null);
  const [formData, setFormData] = useState({
    eventTitle: '',
    eventDate: '',
    eventDescription: '',
    learnMoreLink: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://fwu-soe-diary-backend.onrender.com/api/v1/events/all');
        setEvents(response.data);
      } catch (err) {
        setError('Failed to fetch events');
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Create new event
  const createEvent = async () => {
    try {
      const response = await axios.post('https://fwu-soe-diary-backend.onrender.com/api/v1/events/create', formData);
      setEvents([...events, response.data]);
      setFormData({
        eventTitle: '',
        eventDate: '',
        eventDescription: '',
        learnMoreLink: '',
      });
    } catch (err) {
      setError('Failed to create event');
    }
  };

  // Update event
  const updateEvent = async (id) => {
    try {
      const response = await axios.put(`https://fwu-soe-diary-backend.onrender.com/api/v1/events/update/${id}`, formData);
      setEvents(events.map(event => (event._id === id ? response.data : event)));
      setEditingEvent(null);
      setFormData({
        eventTitle: '',
        eventDate: '',
        eventDescription: '',
        learnMoreLink: '',
      });
    } catch (err) {
      setError('Failed to update event');
    }
  };

  // Delete event
  const deleteEvent = async (id) => {
    try {
      await axios.delete(`https://fwu-soe-diary-backend.onrender.com/api/v1/events/delete/${id}`);
      setEvents(events.filter(event => event._id !== id));
    } catch (err) {
      setError('Failed to delete event');
    }
  };

  // Enter edit mode with event data
  const handleEdit = (event) => {
    setEditingEvent(event._id);
    setFormData({
      eventTitle: event.eventTitle,
      eventDate: new Date(event.eventDate).toISOString().split('T')[0], // Format date for input
      eventDescription: event.eventDescription,
      learnMoreLink: event.learnMoreLink,
    });
  };

  if (loading) return <p className="text-center text-lg text-gray-600">Loading events...</p>;
  if (error) return <p className="text-center text-lg text-red-600">{error}</p>;

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-6 bg-baseBackground min-h-screen font-inter">
        <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor mt-20">Manage Events</h2>

        {/* Create/Edit Event Form */}
        <div className="max-w-lg mx-auto mb-10 bg-background shadow-lg rounded-lg p-6 border-l-4 border-primary">
          <h3 className="teko-unique text-3xl font-bold text-textColor">{editingEvent ? 'Edit Event' : 'Add New Event'}</h3>
          <input
            type="text"
            name="eventTitle"
            value={formData.eventTitle}
            onChange={handleInputChange}
            placeholder="Event Title"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent"
          />
          <input
            type="date"
            name="eventDate"
            value={formData.eventDate}
            onChange={handleInputChange}
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent"
          />
          <textarea
            name="eventDescription"
            value={formData.eventDescription}
            onChange={handleInputChange}
            placeholder="Event Description"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg h-24 focus:outline-none focus:ring-2 focus:ring-accent"
          />
          <input
            type="url"
            name="learnMoreLink"
            value={formData.learnMoreLink}
            onChange={handleInputChange}
            placeholder="Learn More Link"
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent"
          />
          {editingEvent ? (
            <button
              onClick={() => updateEvent(editingEvent)}
              className="w-full bg-secondary text-background py-2 rounded-lg font-semibold flex items-center justify-center gap-2 hover:bg-secondary-dark transition"
            >
              <FiEdit /> Update Event
            </button>
          ) : (
            <button
              onClick={createEvent}
              className="w-full bg-primary text-background py-2 rounded-lg font-semibold flex items-center justify-center gap-2 hover:bg-primary-dark transition"
            >
              <FiPlus /> Add Event
            </button>
          )}
        </div>

        {/* Events List */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {events.map(event => (
            <div key={event._id} className="shadow-md p-6 rounded-lg bg-background border-l-4 border-primary hover:shadow-lg transition-shadow duration-200">
              <h3 className="teko-unique text-2xl font-bold text-center text-textColor mb-2">{event.eventTitle}</h3>
              <p className="text-textColor mb-1">{new Date(event.eventDate).toLocaleDateString()}</p>
              <p className="text-gray-700 mb-3">{event.eventDescription}</p>
              <a
                href={event.learnMoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-accent font-semibold hover:text-accent-dark"
              >
                Learn More
              </a>
              <div className="mt-4 flex space-x-2">
                <button
                  onClick={() => handleEdit(event)}
                  className="flex-1 bg-secondary text-background py-1 px-4 rounded-lg font-semibold flex items-center justify-center gap-1 hover:bg-secondary-dark"
                >
                  <FiEdit /> Edit
                </button>
                <button
                  onClick={() => deleteEvent(event._id)}
                  className="flex-1 bg-red-600 text-background py-1 px-4 rounded-lg font-semibold flex items-center justify-center gap-1 hover:bg-red-700"
                >
                  <FiTrash /> Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EventManagement;
