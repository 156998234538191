import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllExperiences,
    fetchUserExperiences,
    createExperience,
    updateExperience,
    deleteExperience,
    likeExperience,
} from "../redux/experienceSlice.js";
import { useEffect, useCallback } from "react";

const useExperience = () => {
    const dispatch = useDispatch();
    
    // Access experience state from Redux store
    const { experiences, loading, error } = useSelector((state) => state.experience);

    // Fetch all experiences (for admin or general viewing)
    const getAllExperiences = useCallback(() => {
        dispatch(fetchAllExperiences());
    }, [dispatch]);

    // Fetch experiences of the logged-in user
    const getUserExperiences = useCallback((userId) => {
        dispatch(fetchUserExperiences(userId));
    }, [dispatch]);

    // Create a new experience (with multiple images support)
    const addExperience = useCallback((experienceData, images) => {
        // Prepare the form data to include multiple images
        const formData = new FormData();
        Object.entries(experienceData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        // Append each image to the FormData
        images.forEach((image) => formData.append("images", image));

        return dispatch(createExperience(formData));
    }, [dispatch]);

    // Update an experience by ID (support for updating multiple images)
    const modifyExperience = useCallback((id, updates, newImages = []) => {
        const formData = new FormData();

        // Add updated fields to FormData
        Object.entries(updates).forEach(([key, value]) => {
            formData.append(key, value);
        });

        // Add new images if provided
        newImages.forEach((image) => formData.append("images", image));

        return dispatch(updateExperience({ id, updatedData: formData }));
    }, [dispatch]);

    // Delete an experience by ID
    const removeExperience = useCallback((id) => {
        return dispatch(deleteExperience(id));
    }, [dispatch]);

    // Like an experience by ID
    const likeAnExperience = useCallback((id) => {
        return dispatch(likeExperience(id));
    }, [dispatch]);

    // Optionally, fetch all experiences on initial render
    useEffect(() => {
        getAllExperiences();
    }, [getAllExperiences]);

    return {
        experiences,
        loading,
        error,
        getAllExperiences,
        getUserExperiences,
        addExperience,
        modifyExperience,
        removeExperience,
        likeAnExperience,
    };
};

export default useExperience;
