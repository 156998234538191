import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import login from './login.png';
import { setAuthUser } from '../../redux/userSlice';
import { BASE_URL } from '../..';
import { FaLock, FaUser, FaSignInAlt, FaSpinner, FaGoogle, FaGithub } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { googleLogin } from './firebaseConfig';

const Login = () => {
  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUser = Cookies.get('user');
    if (storedUser) {
      navigate('/');
    }
  }, [navigate]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}/api/v1/user/login`, user, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      Cookies.set('user', JSON.stringify(res.data), { expires: 7 });
      navigate('/');
      dispatch(setAuthUser(res.data));
      toast.success('Login successful!');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Login failed. Please try again.');
      console.log(error);
    } finally {
      setLoading(false);
      setUser({
        username: '',
        password: '',
      });
    }
  };

  const handleGoogleLogin = () => {
    console.log('Google login clicked');
  };

  const handleGithubLogin = () => {
    console.log('GitHub login clicked');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-blue-100 px-6">
      <div className="max-w-4xl w-full bg-white rounded-xl shadow-lg overflow-hidden grid grid-cols-1 md:grid-cols-2">
        {/* Left Section: Illustration */}
        <div className="hidden md:flex flex-col items-center justify-center bg-blue-100 p-8 relative">
          <img src={login} alt="Login Illustration" className="w-3/4 mb-6 animate-bounce-slow" />
          <h3 className="text-2xl font-semibold text-blue-600 text-center">
            Welcome Back!
          </h3>
          <p className="text-sm text-blue-500 mt-2 text-center">
            Log in to continue exploring new opportunities and features.
          </p>
          <div className="absolute bottom-4 text-gray-500 text-xs">
            <p>© 2024 Far Western University</p>
          </div>
        </div>

        {/* Right Section: Login Form */}
        <div className="flex flex-col justify-center px-8 py-12 bg-white">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
            Sign In to Your Account
          </h2>
          <form onSubmit={onSubmitHandler} className="space-y-6">
            {/* Username Input */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Username
              </label>
              <div className="flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-inner border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500">
                <FaUser className="text-gray-500 mr-3" />
                <input
                  value={user.username}
                  onChange={(e) => setUser({ ...user, username: e.target.value })}
                  className="flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400"
                  type="text"
                  placeholder="Enter your username"
                  required
                />
              </div>
            </div>

            {/* Password Input */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <div className="flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-inner border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500">
                <FaLock className="text-gray-500 mr-3" />
                <input
                  value={user.password}
                  onChange={(e) => setUser({ ...user, password: e.target.value })}
                  className="flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400"
                  type="password"
                  placeholder="Enter your password"
                  required
                />
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={loading}
              className={`w-full py-3 rounded-lg font-semibold text-white transition-all shadow-md ${
                loading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:opacity-90'
              } flex items-center justify-center`}
            >
              {loading ? <FaSpinner className="animate-spin mr-2" /> : <FaSignInAlt className="mr-2" />}
              {loading ? 'Logging In...' : 'Login'}
            </button>
          </form>

          {/* Divider */}
          <div className="flex items-center my-6">
            <hr className="flex-grow border-gray-200" />
            <span className="mx-4 text-gray-400 text-sm">OR</span>
            <hr className="flex-grow border-gray-200" />
          </div>

          {/* Social Login Buttons */}
          <div className="flex justify-between gap-4">
            <button
              onClick={handleGoogleLogin}
              className="w-full py-3 rounded-lg text-white bg-red-500 hover:bg-red-600 flex items-center justify-center"
            >
              <FaGoogle className="mr-2" /> Google
            </button>
            <button
              onClick={handleGithubLogin}
              className="w-full py-3 rounded-lg text-white bg-gray-800 hover:bg-gray-900 flex items-center justify-center"
            >
              <FaGithub className="mr-2" /> GitHub
            </button>
          </div>

          {/* Signup Redirect */}
          <p className="text-center text-sm text-gray-600 mt-6">
            Don't have an account?{' '}
            <Link to="/signup" className="text-blue-500 font-medium hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
