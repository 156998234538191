import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedUser } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';

const OtherUser = ({ user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedUser, onlineUsers } = useSelector(store => store.user);
    const isOnline = onlineUsers?.includes(user._id);

    const selectedUserHandler = () => {
        dispatch(setSelectedUser(user));
        navigate(`/messages`);
    };

    return (
        <div 
            onClick={selectedUserHandler} 
            className={` 
                ${selectedUser?._id === user?._id ? 'bg-baseBackground text-black' : 'text-textColor hover:bg-baseBackground hover:text-black'}
                flex items-center  rounded-lg p-2 cursor-pointer 
                backdrop-blur-lg bg-white/20 shadow-lg hover:bg-white/30 transition-all duration-200 my-2
            `}
        >
            <div className={`relative ${isOnline ? 'online' : ''}`}>
                <div className="w-12 h-12 sm:w-10 sm:h-10 rounded-full overflow-hidden border-2 border-white shadow-lg">
                    {user?.profilePhoto ? (
                        <img src={user.profilePhoto} alt="user-profile" className="object-cover w-full h-full" />
                    ) : (
                        <FaUserCircle className="text-4xl sm:text-3xl text-gray-300" />
                    )}
                </div>
                {isOnline && (
                    <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></div>
                )}
            </div>
            <div className="flex flex-col flex-1">
                <h6 className="  font-semibold truncate text-[14px] lg:text-[14px]">{user?.fullName}</h6>
                <h5 className={`text-base  ${isOnline ? 'text-green-500' : 'text-gray-400'}`}>
                    {isOnline ? 'Online' : 'Offline'}
                </h5>
            </div>
            <div className="divider my-0 py-0 h-1"></div>
        </div>
    );
};

export default OtherUser;
