import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ImageView = () => {
    const [imageUrl, setImageUrl] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const imgUrl = queryParams.get('imgUrl');
        if (imgUrl) {
            setImageUrl(imgUrl);
        }
    }, [location]);

    if (!imageUrl) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex justify-center items-center h-screen bg-gray-900">
            <img
                src={imageUrl}
                alt="Full view"
                className="max-w-full max-h-full object-contain"
            />
        </div>
    );
};

export default ImageView;
