import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useExperience from '../../hooks/useExperience';
import Navbar from '../Navbar';
import Footer from '../Footer';
import ExperienceList from './ExperienceList';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const { experiences, error, modifyExperience, removeExperience } = useExperience();

    useEffect(() => {
        const userCookie = Cookies.get('user');
        if (userCookie) {
            const parsedUser = JSON.parse(userCookie);
            setUser(parsedUser);
            setFormData(parsedUser);
        } else {
            navigate('/login');
        }
        setLoading(false);
    }, [navigate]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = () => {
        Cookies.set('user', JSON.stringify(formData), { expires: 7 });
        setUser(formData);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setFormData(user);
        setIsEditing(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen bg-background">
                <h2 className="text-titleColor text-2xl font-semibold">Loading profile...</h2>
            </div>
        );
    }

    return (
        <>
            <Navbar />
            <div className="flex flex-col items-center justify-center min-h-screen bg-background py-20 ">
                <div className="bg-baseBackground shadow-lg rounded-lg p-8 w-full max-w-3xl mb-10">
                    <div className="flex items-center mb-6">
                        {user?.profilePhoto && (
                            <div className="relative overflow-hidden w-32 h-32 rounded-full border-4 border-accent mr-4">
                                <img
                                    src={user.profilePhoto}
                                    alt="Profile"
                                    className="w-full h-full object-cover transition-transform duration-300 hover:scale-110 shadow-lg glow-effect"
                                />
                            </div>
                        )}
                        <div className="flex flex-col">
                            <h2 className="text-titleColor font-semibold text-2xl">{user?.username}</h2>
                            <p className="text-lg text-textColor">{user?.fullName}</p>
                            <div className="flex space-x-6 mt-2">
                                <div className="text-center">
                                    <span className="font-bold">{user?.followers.length}</span>
                                    <p className="text-sm text-textColor">Followers</p>
                                </div>
                                <div className="text-center">
                                    <span className="font-bold">{user?.followings.length}</span>
                                    <p className="text-sm text-textColor">Following</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isEditing ? (
                        <div className="space-y-4">
                            <p className="text-lg font-medium text-textColor">
                                <span className="font-bold">Email:</span> {user?.email}
                            </p>
                            <p className="text-lg font-medium text-textColor">
                                <span className="font-bold">Gender:</span> {user?.gender}
                            </p>
                            <p className="text-lg font-medium text-textColor">
                                <span className="font-bold">Bio:</span> {user?.bio || "No bio available"}
                            </p>
                            <p className="text-lg font-medium text-textColor">
                                <span className="font-bold">Website:</span> {user?.website || "No website available"}
                            </p>
                            <p className="text-lg font-medium text-textColor">
                                <span className="font-bold">Created At:</span> {new Date(user?.createdAt).toLocaleDateString()}
                            </p>
                            <p className="text-lg font-medium text-textColor">
                                <span className="font-bold">Updated At:</span> {new Date(user?.updatedAt).toLocaleDateString()}
                            </p>
                            <button
                                className="bg-secondary text-white px-4 py-2 rounded-md mt-4 w-full hover:bg-accent transition duration-300"
                                onClick={() => setIsEditing(true)}
                            >
                                Edit Profile
                            </button>
                        </div>
                    ) : (
                        <form className="space-y-4">
                            <div>
                                <label className="block text-textColor mb-1">Full Name</label>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-accent"
                                />
                            </div>
                            <div>
                                <label className="block text-textColor mb-1">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-accent"
                                />
                            </div>
                            <div>
                                <label className="block text-textColor mb-1">Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-accent"
                                />
                            </div>
                            <div className="flex justify-between">
                                <button
                                    type="button"
                                    className="bg-secondary text-white px-4 py-2 rounded-md hover:bg-accent transition duration-300"
                                    onClick={handleSave}
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition duration-300"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    )}
                </div>

                <ExperienceList
                experiences={experiences}
                handleLike={modifyExperience} // Modify to handle liking/unliking
                handleUnlike={removeExperience} // Modify as per your logic
                handleUpdate={modifyExperience} // You might need a different function for updating
                handleDelete={removeExperience}
            />
            </div>
            <Footer />
        </>
    );
};

export default Profile;
