import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Navbar";



const ShareExperience = () => {
  const [heading, setHeading] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(selectedFile);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImage(null);
      setImagePreview(null);
      alert("Please select a valid image file.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      alert("Please select an image.");
      return;
    }

    const formData = new FormData();
    formData.append("heading", heading);
    formData.append("author", author);
    formData.append("content", content);
    formData.append("image", image);
  };

  const handleCancel = () => {
    setHeading("");
    setAuthor("");
    setContent("");
    setImage(null);
    setImagePreview(null);
    navigate("/experience");
  };

  return (

    <div className="container p-10  lg:px-80 bg-baseBackground shadow-lg mt-20">
      <Navbar/>
      <h2 className="text-titleColor text-3xl font-bold text-center mb-6">
        Share Experience
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4 ">
          <label className="block text-textColor font-semibold mb-2">
            Title:
          </label>
          <input
            type="text"
            placeholder="Enter heading"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            className="w-full p-2 border rounded-md focus:border-primary"
          />
        </div>
        <div className="mb-4">
          <label className="block text-textColor font-semibold mb-2">
            Author:
          </label>
          <input
            type="text"
            placeholder="Enter author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            className="w-full p-2 border rounded-md focus:border-primary"
          />
        </div>
        <div className="mb-4">
          <label className="block text-textColor font-semibold mb-2">
            Content:
          </label>
          <textarea
            placeholder="Enter content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full p-2 border rounded-md h-32 resize-none focus:border-primary"
          />
        </div>
        <div className="mb-4">
          <label className="block text-textColor font-semibold mb-2">
            Image:
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-500"
          />
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected"
              className="mt-4 mx-auto max-w-full max-h-48 rounded-md border"
            />
          )}
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-primary text-white px-4 py-2 rounded-md hover:bg-titleColor"
          >
            Share
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="bg-secondary text-white px-4 py-2 rounded-md hover:bg-accent"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShareExperience;
