import React from "react";
import ExperienceItem from "./ExperienceItem";

const ExperienceList = ({ experiences, handleLike, handleUnlike, handleUpdate, handleDelete }) => (
    <div className="w-full flex flex-col items-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-6xl grid grid-cols-1 gap-6">
            {experiences.length > 0 ? (
                experiences.map((exp, index) => (
                    <div key={exp.id || index} className="w-full">
                        <ExperienceItem
                            experience={exp}
                            handleLike={handleLike}
                            handleUnlike={handleUnlike}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                        />
                    </div>
                ))
            ) : (
                <p className="text-gray-500 text-center">No experiences available.</p>
            )}
        </div>
    </div>
);

export default ExperienceList;
