import React, { useState } from "react";
import ExperiencePrompt from "../ExperiencePrompt";
import Navbar from "../Navbar";
import ExperienceComponent from "./ExperienceComponent";

const dummyExperiences = [
    {
      id: 1,
      title: "My Journey as a Computer Engineering Student",
      author: "Dipa Joshi",
      profilePic: "https://via.placeholder.com/150",
      content:
        "Hello, I’m Dipa Joshi, a student in my fifth semester of Computer Engineering. Looking back at my journey, each semester has been an incredible learning experience. In the beginning, I struggled to balance the intense coursework and extracurricular activities. But as time passed, I learned to embrace every challenge as a new opportunity. The sleepless nights working on projects, hours spent debugging code, and discussions with friends about assignments are all moments that shaped who I am today. Recently, I have been exploring new technologies like AI and machine learning, which have opened up new avenues of interest for me. I can confidently say that these experiences are preparing me not just academically but also personally. I’m excited about the opportunities ahead and grateful for the support I’ve received from my teachers and friends.",
      image: "https://via.placeholder.com/400x200",
    },
    {
      id: 2,
      title: "Exploring New Opportunities",
      author: "Jyotee Singh",
      profilePic: "https://via.placeholder.com/150",
      content:
        "Hi, my name is Jyotee Singh, and I am currently studying Computer Engineering at Far Western University. Being in this program has been an eye-opening experience. I initially joined this field because of my fascination with technology, but over time I discovered my passion for web development. My biggest challenge was transitioning from theoretical knowledge to practical applications. Thanks to the hands-on projects and guidance from my seniors, I now have the confidence to create real-world applications. One of my memorable achievements was presenting a web-based project at a tech fest, which received a lot of appreciation. Apart from academics, I’ve had the chance to organize events and workshops, which helped me develop leadership skills. I believe that my journey here is not just about gaining technical knowledge, but also about becoming a well-rounded individual prepared for future challenges.",
      image: "https://via.placeholder.com/400x200",
    },
    {
      id: 3,
      title: "The Path of a Science Student",
      author: "Kishor Joshi",
      profilePic: "https://via.placeholder.com/150",
      content:
        "I’m Kishor Joshi, currently in Class 11 with a major in Science. Ever since my childhood, I have been fascinated by how things work, which is why I chose the science stream. Right now, my primary focus is on understanding the basics of physics and chemistry, which often involves conducting experiments and observing their outcomes. My teachers have always encouraged me to explore beyond the textbooks, and that’s when I realized that learning is not confined to classrooms. This year, I participated in a science exhibition where I demonstrated a model on renewable energy, which further fueled my interest in sustainability and technology. In the future, I aspire to pursue engineering to contribute to society with innovative solutions. I am grateful to my family and teachers for their constant support and guidance.",
      image: "https://via.placeholder.com/400x200",
    },
  ];
  

const Experience = () => {
  const [experiences] = useState(dummyExperiences);

  return (
    <>
      <Navbar />
      <ExperienceComponent/>
    </>
  );
};

export default Experience;
