import React from 'react'
import EventManagement from '../../Home/EventManagement'

function Admin() {
  return (
   <>
    <EventManagement/>
   </>
  )
}

export default Admin