import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../..";
import { AiOutlinePlus,AiOutlineLoading3Quarters } from 'react-icons/ai'; // Import the spinning icon


const ExperienceForm = () => {
    const [formData, setFormData] = useState({
        title: "Software Engineer",
        company: "Tech Solutions",
        location: "New York, NY",
        startDate: "2023-01-01",
        endDate: "2024-01-01",
        description: "Developed and maintained web applications.",
        role: "Full Stack Developer",
        imgs: [],
    });


    

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            imgs: files,
        }));
    };

    // Spinner Component
const Spinner = () => (
    <AiOutlineLoading3Quarters size={24} className="animate-spin" />
  );
  

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const data = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key === "imgs") {
                    formData[key].forEach((file) => data.append(key, file));
                } else {
                    data.append(key, formData[key]);
                }
            });

            const response = await axios.post(`${BASE_URL}/api/v1/experiences/create/`, data, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            setMessage("Experience created successfully!");
            setFormData({
                title: "",
                company: "",
                location: "",
                startDate: "",
                endDate: "",
                description: "",
                role: "",
                imgs: [],
            });
            setIsModalOpen(false);
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="mt-8">
        {/* Create Button */}
        <button
          className="flex items-center gap-2 mt-20 mb-10 bg-blue-600 text-white dark:bg-blue-900  font-semibold px-6 py-3 rounded-lg shadow-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition-transform transform hover:scale-105"
          onClick={() => setIsModalOpen(true)}
        >
          <AiOutlinePlus size={20} /> Create Experience
        </button>
      
        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-baseBackground dark:bg-darkBackground p-8 rounded-2xl shadow-2xl max-w-4xl w-full relative animate-fade-in border border-gray-300">
              {/* Modal Header */}
              <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800 dark:text-baseBackground">
                Create Experience
              </h2>
      
              {/* Close Button */}
              <button
                className="absolute top-4 right-4 text-gray-500 dark:text-baseBackground hover:text-gray-700 text-3xl focus:outline-none"
                aria-label="Close Modal"
                onClick={() => setIsModalOpen(false)}
              >
                &times;
              </button>
      
              {/* Form */}
              <form onSubmit={handleSubmit} className="space-y-6 max-h-[75vh] overflow-y-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Title */}
                  <div>
                    <label htmlFor="title" className="block text-sm font-medium text-titleColor dark:text-baseBackground mb-2">
                      Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-400 placeholder-bg-gray-400 text-titleColor transition-all duration-300 ease-in-out"
                      placeholder="Enter title"
                    />
                  </div>
      
                  {/* Company */}
                  <div>
                    <label htmlFor="company" className="block text-sm font-medium text-titleColor dark:text-baseBackground mb-2">
                      Company
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-400 placeholder-bg-gray-400 text-titleColor transition-all duration-300 ease-in-out"
                      placeholder="Enter company name"
                    />
                  </div>
                </div>
      
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Location */}
                  <div>
                    <label htmlFor="location" className="block text-sm font-medium text-titleColor dark:text-baseBackground mb-2">
                      Location
                    </label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-400 placeholder-bg-gray-400 text-titleColor transition-all duration-300 ease-in-out"
                      placeholder="Enter location"
                    />
                  </div>
      
                  {/* Role */}
                  <div>
                    <label htmlFor="role" className="block text-sm font-medium text-titleColor dark:text-baseBackground  mb-2">
                      Role
                    </label>
                    <input
                      type="text"
                      id="role"
                      name="role"
                      value={formData.role}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-400 placeholder-bg-gray-400 text-titleColor dark:text-baseBackground transition-all duration-300 ease-in-out"
                      placeholder="Enter role"
                    />
                  </div>
                </div>
      
                {/* Dates */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Start Date */}
                  <div>
                    <label htmlFor="startDate" className="block text-sm font-medium text-titleColor dark:text-baseBackground mb-2">
                      Start Date
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none text-titleColor focus:ring-2 focus:ring-blue-500 bg-gray-400"
                    />
                  </div>
      
                  {/* End Date */}
                  <div>
                    <label htmlFor="endDate" className="block text-sm font-medium text-titleColor dark:text-baseBackground mb-2">
                      End Date
                    </label>
                    <input
                      type="date"
                      id="endDate"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleInputChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none text-titleColor dark:text-baseBackground focus:ring-2 focus:ring-blue-500 bg-gray-400"
                    />
                  </div>
                </div>
      
                {/* Description */}
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-titleColor dark:text-baseBackground mb-2">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-bg-gray-400 text-titleColor dark:text-baseBackground bg-gray-400 transition-all duration-300 ease-in-out"
                    placeholder="Write a brief description"
                  ></textarea>
                </div>
      
                {/* Upload Images */}
                <div>
                  <label htmlFor="imgs" className="block text-sm font-medium text-titleColor dark:text-baseBackground mb-2">
                    Upload Images
                  </label>
                  <input
                    type="file"
                    id="imgs"
                    name="imgs"
                    onChange={handleFileChange}
                    multiple
                    className="w-full p-3 border border-gray-300 rounded-lg shadow-sm"
                  />
                </div>
      
                {/* Submit Button */}
                <div className="flex justify-center mt-6">
                  <button
                    type="submit"
                    className={`w-1/2 py-3 rounded-lg shadow-md transform transition-transform ${
                      isSubmitting
                        ? "bg-blue-800 cursor-not-allowed"
                        : "bg-blue-600 text-white hover:bg-blue-500 hover:scale-105"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span className="flex justify-center items-center gap-2">
                        <Spinner /> Submitting...
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      
        {/* Message */}
        {message && (
          <p className="mt-4 text-center text-green-600 font-semibold">{message}</p>
        )}
      </div>
      
      
    );
};

export default ExperienceForm;
