import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FaCheckCircle } from 'react-icons/fa';
import { format } from 'date-fns';

const Message = ({ message, loading }) => {
  const scroll = useRef();
  const { authUser, selectedUser } = useSelector((store) => store.user);

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: 'smooth' });
  }, [message]);

  const formattedTime = format(new Date(message?.createdAt), 'hh:mm a');

  return (
    <div
      ref={scroll}
      className={`chat flex items-start ${
        message?.senderId === authUser?._id ? 'chat-end justify-end' : 'chat-start'
      }`}
    >
      {loading ? (
        <span className="loading loading-ring loading-lg mx-auto"></span>
      ) : (
        <>
          <div className={`chat-image avatar ${message?.senderId === authUser?._id ? 'ml-2' : 'mr-2'}`}>
            <div className="w-10 h-10 rounded-full bg-baseBackground border border-gray-300 shadow-md">
              <img
                alt="User Avatar"
                src={message?.senderId === authUser?._id ? authUser?.profilePhoto : selectedUser?.profilePhoto}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="chat-header flex items-center">
              <time className="text-xs text-gray-500 opacity-70">{formattedTime}</time>
              {message?.isRead && <FaCheckCircle className="ml-2 text-green-500" size={12} />}
            </div>
            <div
              className={`chat-bubble ${
                message?.senderId === authUser?._id ? 'bg-primary text-white' : 'bg-gray-200 text-black'
              } rounded-lg p-3 shadow-md transition-all duration-200 relative`}
            >
              <div className="message-content">{message?.message}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Message;