import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaHeart, FaComment, FaShare, FaTrash, FaUserCircle, FaRegCommentDots } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { likeExperience, unlikeExperience } from "../../../src/redux/experienceSlice";
import useGetOtherUsers from "../../hooks/useGetOtherUsers";
import Cookies from "js-cookie";
import CommentForm from "./CommentsForm";
import axios from "axios";

const ExperienceItem = ({ experience, handleUpdate, handleDelete }) => {
  const [user, setUser] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(experience.likesCount || 0);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [hasMoreComments, setHasMoreComments] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useGetOtherUsers(experience.user?._id);

  useEffect(() => {
    const userCookie = Cookies.get("user");
    // console.log("User cookie:", userCookie);
    if (userCookie) {
      const parsedUser = JSON.parse(userCookie);
      setUser(parsedUser);
      // console.log("Parsed user:", parsedUser);
    } else {
      console.warn("No user cookie found. Redirecting to login.");
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    // console.log("Checking if user has liked the experience");
    if (user && experience.likes.includes(user._id)) {
      setIsLiked(true);
      // console.log("User has liked this experience.");
    } else {
      setIsLiked(false);
      // console.log("User has not liked this experience.");
    }
  }, [user, experience.likes]);

  const handleLike = async (experienceId) => {
    // console.log("Liking experience with ID:", experienceId);
    try {
      const token = Cookies.get("token");
      const response = await axios.post(
        `https://fwu-soe-diary-backend.onrender.com/api/v1/experiences/like/${experienceId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Like response:", response.data);
      setLikesCount(response.data.experience.likesCount);
      setIsLiked(true);
    } catch (error) {
      // console.error("Error liking experience:", error);
    }
  };

  const handleUnlike = async (id) => {
    // console.log("Unliking experience with ID:", id);
    try {
      const token = Cookies.get("token");
      const response = await axios.post(
        `https://fwu-soe-diary-backend.onrender.com/api/v1/experiences/unlike/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Unlike response:", response.data);
      setLikesCount(response.data.experience.likesCount);
      setIsLiked(false);
    } catch (error) {
      // console.error("Error unliking the experience:", error);
    }
  };

  const handleDeleteWithNotification = async (id) => {
    // console.log("Deleting experience with ID:", id);
    try {
      await handleDelete(id);
      // toast.success("Experience deleted successfully!");
    } catch (error) {
      // console.error("Error deleting experience:", error);
      toast.error("Failed to delete experience.");
    }
  };

  const handleShare = () => {
    const shareLink = `${window.location.origin}/experience/${experience._id}`;
    // console.log("Sharing link:", shareLink);
    navigator.clipboard
      .writeText(shareLink)
      .then(() => toast.success("Link copied to clipboard!"))
      .catch(() => toast.error("Failed to copy link."));
  };

  const doubleClickHandler = () => {
    // console.log("Double-click detected. Current like status:", isLiked);
    if (isLiked) {
      handleUnlike(experience._id);
    } else {
      handleLike(experience._id);
    }
  };

  const fetchComments = async () => {
    // console.log("Fetching comments for experience:", experience._id);
    try {
      const response = await axios.get(
        `https://fwu-soe-diary-backend.onrender.com/api/v1/comments/${experience._id}/comments`
      );
      console.log("Fetched comments:", response.data.comments);
      if (Array.isArray(response.data.comments)) {
        setComments(response.data.comments);
      } else {
        console.warn("No comments found.");
        setComments([]);
      }
      setShowComments((prevState) => !prevState);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleCommentSubmit = async (commentData) => {
    // console.log("Submitting comment:", commentData);
    try {
      const token = Cookies.get("token");
      const response = await axios.post(
        `https://fwu-soe-diary-backend.onrender.com/api/v1/comments/${experience._id}`,
        commentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Comment submit response:", response.data);
      toast.success("Comment posted successfully!");
      fetchComments();
    } catch (error) {
      console.error("Error posting comment:", error);
      toast.error("Failed to post comment.");
    }
  };


  return (
<div
  onDoubleClick={doubleClickHandler}
  className="max-w-6xl mx-auto mb-8 p-4 md:p-6 bg-baseBackground dark:bg-darkBackground rounded-lg shadow-2xl transition-transform duration-200 cursor-pointer border border-gray-300 dark:border-gray-700 hover:shadow-xl hover:-translate-y-2"
>
<div className="flex items-center">
  {userProfile?.profilePhoto ? (
    <img
      src={userProfile.profilePhoto}
      alt="Profile"
      className="w-16 h-16 md:w-20 md:h-20 rounded-full object-cover border-2 border-primary dark:border-accent shadow-md"
      onClick={() => navigate(`/users/${userProfile._id}`)}
    />
  ) : (
    <div className="w-16 h-16 md:w-20 md:h-20 bg-gray-300 dark:bg-gray-600 rounded-full flex items-center justify-center">
      <FaUserCircle className="text-4xl text-gray-500 dark:text-gray-300" />
    </div>
  )}

  <div className="ml-3">
    <h3 className="text-lg md:text-xl font-semibold text-titleColor dark:text-lightGrayBackground">
      {userProfile?.fullName || "Unknown User"}
    </h3>
    <p className="text-sm md:text-base text-gray-500 dark:text-gray-400">{experience.company}</p>
  </div>
</div>


  {experience.images?.length > 0 && (
    <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 gap-3 md:gap-4">
      {experience.images.map((img, index) => (
        <div
          key={img._id || index}
          className="relative rounded-lg overflow-hidden shadow-md group"
          onClick={() => navigate(`/image-view?imgUrl=${encodeURIComponent(img.imgUrl)}`)}
        >
          <img
            src={img.imgUrl}
            alt={`Experience Image ${index + 1}`}
            loading="lazy"
            className="w-full h-40 md:h-60 object-cover transition-transform duration-200 group-hover:scale-105"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/path/to/fallback-image.png";
            }}
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
        </div>
      ))}
    </div>
  )}

  <div className="mt-4">
    <h3 className="text-lg md:text-xl font-semibold text-titleColor dark:text-lightGrayBackground">
      {experience.title}
    </h3>
    <p className="text-xs md:text-sm italic text-gray-500 dark:text-gray-400">{experience.location}</p>
    <p className="mt-2 text-sm md:text-base text-titleColor dark:text-lightGrayBackground leading-relaxed">
      {experience.description}
    </p>
    <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
      {new Date(experience.startDate).toLocaleDateString()} - {new Date(experience.endDate).toLocaleDateString()}
    </p>
  </div>

  <div className="mt-4 flex flex-wrap justify-between items-center border-t border-gray-300 dark:border-gray-700 pt-4">
    <div className="flex space-x-4">
      <button onClick={isLiked ? () => handleUnlike(experience._id) : () => handleLike(experience._id)} className="flex items-center space-x-1 hover:text-red-500">
        <FaHeart className={`w-5 h-5 ${isLiked ? "text-red-500" : "text-gray-400"}`} />
        <span className="text-sm">{likesCount}</span>
      </button>
      <button onClick={fetchComments} className="flex items-center space-x-1 hover:text-blue-500">
        <FaComment className="w-5 h-5 text-gray-400" />
        <span className="text-sm">Comment</span>
      </button>
      <button onClick={handleShare} className="flex items-center space-x-1 hover:text-green-500">
        <FaShare className="w-5 h-5 text-gray-400" />
        <span className="text-sm">Share</span>
      </button>
    </div>
    {String(user?._id) === String(experience.user?._id) && (
      <button onClick={() => handleDeleteWithNotification(experience._id)} className="hover:text-red-500">
        <FaTrash className="w-5 h-5 text-gray-400" />
      </button>
    )}
  </div>

  {showComments && (
    <div className="mt-6">
      {comments.length === 0 ? (
        <div className="text-center py-6 bg-gray-100 dark:bg-gray-800 rounded-lg">
          <p className="text-lg font-semibold text-titleColor dark:text-lightGrayBackground">Be the first to leave a comment!</p>
          <FaRegCommentDots className="text-4xl text-accent mt-2" />
        </div>
      ) : (
        comments.map((comment) => (
          <div key={comment.id} className="p-3 bg-lightGrayBackground dark:bg-gray-800 rounded-lg shadow-sm mb-3">
            <div className="flex items-center space-x-3">
              {comment.user?.profilePhoto ? (
                <img src={comment.user.profilePhoto} alt="User" className="w-8 h-8 rounded-full" />
              ) : (
                <FaUserCircle className="w-8 h-8 text-gray-500 dark:text-gray-300" />
              )}
              <div>
                <p className="font-bold">{comment.user?.username || "Anonymous"}</p>
                <p className="text-xs text-gray-500">{new Date(comment.createdAt).toLocaleString()}</p>
              </div>
            </div>
            <p className="mt-2 text-sm">{comment.text}</p>
          </div>
        ))
      )}
      <CommentForm experienceId={experience._id} onSubmit={handleCommentSubmit} />
    </div>
  )}
</div>



  );
};

export default ExperienceItem;
