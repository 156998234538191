import React, { useState } from "react";

const CommentForm = ({ experienceId, onSubmit }) => {
    const [comment, setComment] = useState('');
    const [imageId, setImageId] = useState(''); // Set up an image ID if necessary

    const handleSubmit = (e) => {
        e.preventDefault();
        if (comment.trim() === '') {
            return;
        }

        const commentData = {
            experienceId: experienceId,
            comment: comment,
            imageId: imageId,
        };

        onSubmit(commentData); // Pass data to the parent component
        setComment(''); // Reset comment field
    };

    return (
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 p-6 bg-baseBackground rounded-lg shadow-lg max-w-lg mx-auto">
            <h2 className="text-textColor text-2xl font-semibold">Add Your Comment</h2>
            
            <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Add a comment..."
                className="w-full p-4 rounded-lg border-2 bg-transparent border-gray-300 focus:outline-none focus:ring-2 focus:ring-accent focus:border-baseBackground transition-all duration-200 ease-in-out text-textColor"
                rows="4"
            />

            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    {/* Add image upload input or preview here if needed */}
                    {/* <label htmlFor="image" className="cursor-pointer text-accent hover:underline">
                        Attach Image
                    </label> */}
                    <input
                        id="image"
                        type="file"
                        onChange={(e) => setImageId(e.target.files[0])}
                        className="hidden"
                    />
                </div>

                <button
                    type="submit"
                    className="py-3 px-8 bg-accent text-titleColor rounded-lg hover:bg-accentlight focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50 transition-all duration-200 ease-in-out"
                >
                    Post Comment
                </button>
            </div>
        </form>
    );
};

export default CommentForm;
