import React, { useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import OtherUsers from './OtherUsers';
import { useSelector, useDispatch } from 'react-redux';
import { setOtherUsers } from '../../redux/userSlice';
import toast from 'react-hot-toast';
import Footer from '../Footer';
import Navbar from '../Navbar';

const FindUser = () => {
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const { otherUsers, allUsers } = useSelector(store => store.user); // Access all users to reset the list

    const searchSubmitHandler = (e) => {
        e.preventDefault();
        const conversationUser = otherUsers.find(user => 
            user.fullName.toLowerCase().includes(search.toLowerCase())
        );

        if (conversationUser) {
            dispatch(setOtherUsers([conversationUser])); // Update Redux store with found user
            setSearch(""); // Clear the search input
        } else {
            toast.error("User not found!");
        }
    };

    const clearSearchHandler = () => {
        dispatch(setOtherUsers(allUsers)); // Reset to show all users
        setSearch(""); // Clear the search input
    };

    return (
        <>
            <Navbar /> 
            <div className="flex flex-col items-center justify-center min-h-screen bg-background dark:bg-darkBackground p-4 mt-2 lg:mt-2">
                <div className="bg-baseBackground dark:bg-gray-800 shadow-lg rounded-lg w-full sm:px-2 sm:mx-2 mt-4">
                    <h2 className="teko-unique text-4xl font-bold text-center mb-2 text-textColor dark:text-lightGrayBackground mt-16">
                        Find Users
                    </h2>
                    
                    <form onSubmit={searchSubmitHandler} className="flex items-center gap-2 mb-4 w-2/3 mx-auto bg-baseBackground dark:bg-darkBackground p-4 rounded-lg shadow-lg border dark:border-gray-700">
                        <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="w-full p-2 pr-10 border border-gray-400 dark:border-gray-600 rounded-lg text-textColor dark:text-lightGrayBackground bg-baseBackground dark:bg-darkBackground placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-200 shadow-inner hover:shadow-lg"
                            type="text"
                            placeholder="Search for users..."
                        />
                        <button
                            type="submit"
                            className="flex items-center justify-center p-2 text-textColor dark:text-lightGrayBackground bg-baseBackground dark:bg-darkBackground rounded-lg hover:bg-opacity-80 transition duration-200 shadow-md transform hover:scale-105">
                            <BiSearchAlt2 className="w-6 h-6" />
                        </button>
                        <button
                            type="button"
                            onClick={clearSearchHandler}
                            className="flex items-center justify-center p-2 text-textColor dark:text-lightGrayBackground bg-accent dark:bg-accentDark rounded-lg hover:bg-accentlight dark:hover:bg-accentlight transition duration-200 shadow-md transform hover:scale-105">
                            Clear
                        </button>
                    </form>

                    {/* Other Users */}
                    <div className='flex-1 overflow-y-auto max-h-[70vh] scrollbar-thin scrollbar-thumb-baseBackground dark:scrollbar-thumb-darkGray scrollbar-track-gray-800'>
                        <OtherUsers /> {/* Use the OtherUsers component here */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FindUser;
