import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch } from "react-redux";
import { setOtherUsers } from '../redux/userSlice';
import { BASE_URL } from '..';

const useGetOtherUsers = (userId) => {
    const dispatch = useDispatch();
    const [userProfile, setUserProfile] = useState(null); // Store specific user data

    useEffect(() => {
        const fetchOtherUsers = async () => {
            try {
                axios.defaults.withCredentials = true;
                const url = userId ? `${BASE_URL}/api/v1/user/${userId}` : `${BASE_URL}/api/v1/user`;
                const res = await axios.get(url);

                if (userId) {
                    setUserProfile(res.data); // Store the specific user's profile
                } else {
                    dispatch(setOtherUsers(res.data)); // Store all users in redux
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchOtherUsers();
    }, [userId, dispatch]);

    return userProfile; // Return the specific user's profile when userId is provided
}

export default useGetOtherUsers;
