import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegComments, FaUserFriends, FaHome, FaUserCircle, FaUserShield, FaSearch, FaSun, FaMoon } from 'react-icons/fa';
import { HiOutlineMenu, HiOutlineX } from 'react-icons/hi';
import Cookies from 'js-cookie';
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { setAuthUser, setOtherUsers, setSelectedUser } from '../redux/userSlice';
import { setMessages } from '../redux/messageSlice';
import Logo0 from "./soeconnect-logo-bgremoved.png";
import { BASE_URL } from '../';
import axios from 'axios';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);
    const { authUser } = useSelector(store => store.user);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const userCookie = Cookies.get('user');
        if (userCookie) {
            const parsedUser = JSON.parse(userCookie);
            setUser(parsedUser);
        }
    }, []);

    useEffect(() => {
        const currentMode = localStorage.getItem('darkMode') === 'true';
        setIsDarkMode(currentMode);
        if (currentMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    const handleScroll = () => {
        if (window.scrollY > lastScrollY && window.scrollY > 100) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleProfileClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        // Clear cookies and localStorage
        Cookies.remove('user');
        Cookies.remove('token');
        localStorage.removeItem('persist:root');
        localStorage.removeItem('loginTimestamp');
      
        // Reset Redux state
        dispatch(setAuthUser(null));
        dispatch(setMessages(null));
        dispatch(setOtherUsers(null));
        dispatch(setSelectedUser(null));
      
        // Navigate to login page
        navigate('/login');
        
        // Show a success message
        toast.success('You have been logged out.');
    };

    const toggleDarkMode = () => {
        const newMode = !isDarkMode;
        setIsDarkMode(newMode);
        localStorage.setItem('darkMode', newMode);
        if (newMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    return (
        <nav
        className={`bg-baseBackground dark:bg-gray-500 shadow-inner fixed top-0 left-0 w-full z-50 transition-transform duration-300 ${
          isVisible ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          {/* Logo */}
          <Link
            to="/"
            className="flex items-center p-2 rounded transition-transform transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
            aria-label="Home"
          >
            <img src={Logo0} alt="SOE Connect Logo" className="w-18 h-16" />
          </Link>
      
          {/* Search Bar (Desktop) */}
          <div className="hidden md:flex items-center relative w-1/3">
            <input
              type="text"
              placeholder="Search..."
              className="w-full px-4 py-2 rounded-lg shadow-inner text-sm text-textColor focus:ring-2 focus:ring-accent bg-white dark:bg-gray-700 dark:text-gray-300"
            />
            <FaSearch className="absolute right-3 text-gray-400 dark:text-gray-500" />
          </div>
      
          {/* Desktop Links */}
          <div className="hidden md:flex items-center space-x-6">
            {['/', '/messages', '/experience', '/findusers'].map((path, idx) => {
              const icons = [
                <FaHome />,
                <FaRegComments />,
                <FaUserFriends />,
                <FaUserFriends />,
              ];
              const labels = ['Home', 'Chat', 'Experiences', 'Find User'];
      
              return (
                <Link
                  to={path}
                  key={idx}
                  className={`text-textColor flex flex-col items-center ${
                    window.location.pathname === path
                      ? 'border-b-2 border-accent' // Active link underline
                      : 'hover:text-accent'
                  }`}
                >
                  {icons[idx]}
                  <span>{labels[idx]}</span>
                </Link>
              );
            })}
      
            {user && user.role === 'admin' && (
              <Link
                to="/admin"
                className={`text-textColor flex flex-col items-center ${
                  window.location.pathname === '/admin' ? 'border-b-2 border-accent' : 'hover:text-accent'
                }`}
              >
                <FaUserShield />
                <span>Admin</span>
              </Link>
            )}
      
            {/* Profile Dropdown (Desktop) */}
            {authUser && (
              <div className="relative">
                <button
                  onClick={handleProfileClick}
                  className="flex items-center p-2 rounded-lg text-textColor hover:bg-accentLight dark:hover:bg-accentDark"
                >
                  <FaUserCircle className="w-6 h-6" />
                </button>
                {/* Dropdown Menu */}
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 bg-white dark:bg-gray-800 shadow-lg rounded-lg w-48">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-textColor hover:bg-accentLight dark:text-gray-300 dark:hover:bg-accentDark"
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-textColor hover:bg-accentLight dark:text-gray-300 dark:hover:bg-accentDark text-left"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
      
          {/* Mode Toggle */}
          <div className="hidden md:flex items-center">
            <button
              onClick={toggleDarkMode}
              className="flex items-center p-2 rounded-lg bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
              aria-label="Toggle Dark Mode"
            >
              {isDarkMode ? (
                <FaSun className="text-yellow-500" />
              ) : (
                <FaMoon className="text-blue-500" />
              )}
            </button>
          </div>
      
          {/* Profile and Mobile Menu Icons */}
          <div className="flex md:hidden items-center space-x-4">
            <button
              onClick={toggleDarkMode}
              className="flex items-center p-2 rounded-lg bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
              aria-label="Toggle Dark Mode"
            >
              {isDarkMode ? (
                <FaSun className="text-yellow-500" />
              ) : (
                <FaMoon className="text-blue-500" />
              )}
            </button>
            <button onClick={toggleMenu} className="text-textColor focus:outline-none">
              {isOpen ? <HiOutlineX className="w-6 h-6" /> : <HiOutlineMenu className="w-6 h-6" />}
            </button>
      
            {/* Profile Dropdown (Mobile) */}
            {authUser && (
              <div className="relative">
                <button
                  onClick={handleProfileClick}
                  className="flex items-center p-2 rounded-lg text-textColor hover:bg-accentLight dark:hover:bg-accentDark"
                >
                  <FaUserCircle className="w-6 h-6" />
                </button>
                {/* Dropdown Menu */}
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 bg-white dark:bg-gray-800 shadow-lg rounded-lg w-48">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-textColor hover:bg-accentLight dark:text-gray-300 dark:hover:bg-accentDark"
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-textColor hover:bg-accentLight dark:text-gray-300 dark:hover:bg-accentDark text-left"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      
        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden bg-baseBackground shadow-inner">
            <div className="px-4 py-2">
              <input
                type="text"
                placeholder="Search..."
                className="w-full px-4 py-2 rounded-lg shadow-inner text-sm text-textColor focus:ring-2 focus:ring-accent bg-white dark:bg-gray-700 dark:text-gray-300"
              />
            </div>
            <Link to="/" className="px-4 py-2 text-textColor hover:bg-accentLight flex items-center">
              <FaHome className="mr-1" /> Home
            </Link>
            <Link to="/messages" className="px-4 py-2 text-textColor hover:bg-accentLight flex items-center">
              <FaRegComments className="mr-1" /> Chat
            </Link>
            <Link to="/experience" className="px-4 py-2 text-textColor hover:bg-accentLight flex items-center">
              <FaUserFriends className="mr-1" /> Experiences
            </Link>
            <Link to="/findusers" className="px-4 py-2 text-textColor hover:bg-accentLight flex items-center">
              <FaUserFriends className="mr-1" /> Find User
            </Link>
            {user && user.role === 'admin' && (
              <Link
                to="/admin"
                className="px-4 py-2 text-textColor hover:bg-accentLight flex items-center"
              >
                <FaUserShield className="mr-1" /> Admin
              </Link>
            )}
            <div className="block px-4 py-2 text-textColor hover:bg-accentLight">
              <button onClick={handleLogout} className="w-full">
                Logout
              </button>
            </div>
          </div>
        )}
      </nav>
      
    
    );
};

export default Navbar;
