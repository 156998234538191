import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EventsSection = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  // Fetch all events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://fwu-soe-diary-backend.onrender.com/api/v1/events/all');
        setEvents(response.data);
      } catch (err) {
        setError('Failed to fetch events');
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  const openShareModal = (url) => {
    setShareUrl(url);
    setShowModal(true);
  };

  const closeShareModal = () => {
    setShowModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    alert('Link copied to clipboard!');
  };

  if (loading) return <p>Loading events...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-6 bg-baseBackground dark:bg-gray-800">
      <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor dark:text-white">Upcoming Events</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {events.map(event => (
          <div key={event._id} className="shadow-md p-4 rounded bg-baseBackground dark:bg-gray-700">
            <h3 className="font-bold text-textColor dark:text-white">{event.eventTitle}</h3>
            <p className="text-textColor dark:text-gray-300">{new Date(event.eventDate).toLocaleDateString()}</p>
            <p className="text-textColor dark:text-gray-300">{event.eventDescription}</p>
            <div className="flex gap-2 mt-2">
              <button
                className="bg-primary text-white py-1 px-2 rounded hover:bg-primaryDark dark:bg-primaryDark dark:hover:bg-primary"
                onClick={() => window.location.href = event.learnMoreLink}
              >
                Learn More
              </button>
              <button
                className="bg-accent hover:bg-accentlight text-white py-1 px-2 rounded dark:bg-accent dark:hover:bg-accentlight"
                onClick={() => openShareModal(event.learnMoreLink)}
              >
                Share
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Share Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-80">
            <h3 className="text-lg text-titleColor font-semibold mb-4 dark:text-white">Share This Event</h3>
            <p className="text-sm mb-4 text-textColor dark:text-gray-300">Copy the link below to share this event:</p>
            <div className="flex items-center mb-4">
              <input
                type="text"
                readOnly
                value={shareUrl}
                className="border p-2 w-full mr-2 rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
              <button
                className="bg-primary text-white px-3 py-1 rounded dark:bg-primaryDark dark:hover:bg-primary"
                onClick={copyToClipboard}
              >
                Copy
              </button>
            </div>
            <button
              className="text-gray-500 underline dark:text-gray-300"
              onClick={closeShareModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsSection;
