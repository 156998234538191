import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaUserCircle } from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setUserById, followUser, unfollowUser } from '../../redux/userSlice'; 
import Navbar from '../Navbar';
import Footer from '../Footer';
import { BASE_URL } from '../../';

const UserProfile = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const { userById, authUser } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const userCookie = Cookies.get("user");
                const token = userCookie ? JSON.parse(userCookie).token : null;

                if (!token) {
                    setError("User is not authenticated");
                    setLoading(false);
                    return;
                }

                const response = await axios.get(`${BASE_URL}/api/v1/user/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                dispatch(setUserById(response.data));
                const currentUserId = authUser._id;
                setIsFollowing(response.data.followers.includes(currentUserId)); 
                setLoading(false);
            } catch (error) {
                console.error("Error fetching user data:", error);
                setError("Failed to fetch user data");
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId, dispatch, authUser._id]);

    const handleFollow = async () => {
        const userCookie = Cookies.get("user");
        const token = userCookie ? JSON.parse(userCookie).token : null;

        if (!token) {
            toast.error("Authorization token is missing. Please log in.");
            return;
        }

        if (isFollowing) {
            toast.info("You are already following this user.");
            return; // Prevent further action if already following
        }

        try {
            await axios.post(
                `${BASE_URL}/api/v1/user/follow`,
                { userIdToFollow: userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Dispatch follow action
            dispatch(followUser(userId));
            setIsFollowing(true);
            toast.success("You are now following this user!");
        } catch (error) {
            console.error("Error following user:", error.response?.data || error.message);
            toast.error("Failed to follow the user. Please try again.");
        }
    };

    const handleUnfollow = async () => {
        const userCookie = Cookies.get("user");
        const token = userCookie ? JSON.parse(userCookie).token : null;

        if (!token) {
            toast.error("Authorization token is missing. Please log in.");
            return;
        }

        try {
            await axios.post(
                `${BASE_URL}/api/v1/user/unfollow`,
                { userIdToUnfollow: userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Dispatch unfollow action
            dispatch(unfollowUser(userId));
            setIsFollowing(false);
            toast.info("You have unfollowed this user.");
        } catch (error) {
            console.error("Error unfollowing user:", error.response?.data || error.message);
            toast.error("Failed to unfollow the user. Please try again.");
        }
    };

    const handleSendMessage = () => {
        // Navigate to the /message page
        navigate('/messages');
        // Show a toast notification
        toast.info("Proceeding to messages...<4");
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>; // Center loading text
    }

    if (error) {
        return <div className="text-center text-red-500">{error}</div>; // Center error message
    }

    return (
        <>
            <Navbar />
            <div className="bg-gray-100 p-4 pt-20">
                <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg">
                    <div className="flex flex-col md:flex-row items-center border-b p-4">
                        <img 
                            src={userById?.profilePhoto || "defaultProfilePic.png"} 
                            alt={userById?.fullName || "User Profile"} 
                            className="rounded-full w-32 h-32 border border-gray-300"
                        />
                        <div className="ml-4 flex-1">
                            <h2 className="text-2xl font-semibold">{userById?.fullName || "User Name"}</h2>
                            <p className="text-gray-500">{userById?.username || "Username"}</p>
                            <p className="text-gray-600">{`Followers: ${userById?.followers?.length || 0}`}</p>
                            <p className="text-gray-600">{`Following: ${userById?.following?.length || 0}`}</p>
                            <div className="mt-2 space-x-4">
                                {isFollowing ? (
                                    <button
                                        onClick={handleUnfollow}
                                        className="px-4 py-2 rounded-md text-white bg-red-500 hover:bg-red-600 transition"
                                    >
                                        Unfollow
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleFollow}
                                        className="px-4 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600 transition"
                                    >
                                        Follow
                                    </button>
                                )}
                                <button
                                    onClick={handleSendMessage}
                                    className="px-4 py-2 rounded-md text-white bg-green-500 hover:bg-green-600 transition"
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="p-4">
                        <h3 className="text-lg font-semibold mb-2">Bio</h3>
                        <p className="text-gray-700">{userById?.bio || "No bio available."}</p>
                    </div>

                    <div className="p-4">
                        <h3 className="text-lg font-semibold mb-2">Recent Posts</h3>
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                            {userById?.posts?.length ? (
                                userById.posts.map((post) => (
                                    <div key={post._id} className="relative overflow-hidden">
                                        <img 
                                            src={post.imageUrl || "defaultPostImage.png"} 
                                            alt="Post" 
                                            className="w-full h-32 object-cover rounded-lg"
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="col-span-2 text-center">No posts available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UserProfile;
