import React, { useState, useRef, useEffect } from 'react';
import { BiSearchAlt2, BiMenu } from "react-icons/bi";
import { FiLogOut, FiUser } from "react-icons/fi";
import OtherUsers from './OtherUsers';
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuthUser, setOtherUsers, setSelectedUser } from '../../redux/userSlice';
import { setMessages } from '../../redux/messageSlice';
import { BASE_URL } from '../..';
import Cookies from 'js-cookie'; 
import { motion } from 'framer-motion';

const Sidebar = () => {
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const { otherUsers, authUser } = useSelector(store => store.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarRef = useRef(null);

    const toggleSidebar = () => setIsOpen(!isOpen);

    const logoutHandler = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/api/v1/user/logout`);
            toast.success(res.data.message);
            Cookies.remove('user');
            Cookies.remove('token');
            localStorage.removeItem('persist:root');
            dispatch(setAuthUser(null));
            dispatch(setMessages(null));
            dispatch(setOtherUsers(null));
            dispatch(setSelectedUser(null));
            navigate("/login");
        } catch (error) {
            console.log(error);
            toast.error("Logout failed! Please try again.");
        }
    };

    const searchSubmitHandler = (e) => {
        e.preventDefault();
        const conversationUser = otherUsers?.find(user => user.fullName.toLowerCase().includes(search.toLowerCase()));
        if (conversationUser) {
            dispatch(setOtherUsers([conversationUser]));
            setSearch("");
        } else {
            toast.error("User not found!");
        }
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <motion.button
                onClick={toggleSidebar}
                className={`p-3 text-textColor bg-baseBackground rounded-md hover:bg-opacity-80 fixed top-1 left-4 z-50 transition-all duration-300 ${isOpen ? "hidden" : "block"}`}
            >
                <BiMenu className="w-6 h-6" />
            </motion.button>

            <motion.button
                onClick={toggleSidebar}
                className={`p-3 text-textColor bg-baseBackground rounded-md hover:bg-opacity-80 fixed top-1 left-4 z-50 transition-all duration-300 ${isOpen ? "block" : "hidden"}`}
            >
                X
            </motion.button>

            <motion.div
                ref={sidebarRef}
                initial={{ x: '-100%' }}
                animate={{ x: isOpen ? '0%' : '-100%' }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className="fixed top-0 left-0 h-full bg-gradient-to-b from-baseBackground to-gray-700 w-[300px] shadow-lg rounded-lg overflow-hidden max-w-full z-40"
            >
                <div className="flex flex-col h-full p-4">
                    {/* Profile Section */}
                    <div className="flex items-center">
                        <div className="w-16 h-16 rounded-full overflow-hidden border-2 border-primary ml-10">
                            <img
                                src={authUser?.profilePhoto}
                                alt="Profile"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className="ml-3 text-textColor">
                            <p className="font-semibold text-lg">{authUser?.fullName}</p>
                            <div className="flex items-center text-sm text-gray-400">
                                <FiUser className="mr-1" />
                                <span>{authUser?.role}</span>
                            </div>
                        </div>
                    </div>

                    {/* Search Input */}
                    <form onSubmit={searchSubmitHandler} className="relative mb-4">
                        <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="w-full p-2 border border-gray-400 rounded-lg bg-gray-600 text-text-primary placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-primary transition duration-200 shadow-md hover:shadow-lg"
                            type="text"
                            placeholder="Search..."
                        />
                        <button
                            type="submit"
                            className="absolute right-2 p-2 text-white bg-primary rounded-lg hover:bg-opacity-80 transition duration-200 shadow-md transform hover:scale-105">
                            <BiSearchAlt2 className="w-6 h-6" />
                        </button>
                    </form>

                    <div className="my-2 border-b border-gray-500" />

                    {/* Other Users */}
                    <div className='flex-1 overflow-y-auto max-h-[60vh] scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800'>
                        <OtherUsers onUserClick={toggleSidebar} />
                    </div>

                    {/* Navigation Buttons */}
                    <div className="mt-4 flex flex-col gap-3">
                        <button
                            onClick={() => navigate('/')}
                            className="flex items-center w-full p-2 text-sm text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200 shadow-md transform hover:scale-105">
                            <FiLogOut className="mr-2" />
                            Home
                        </button>

                        <button
                            onClick={logoutHandler}
                            className="flex items-center w-full p-2 text-sm text-white bg-red-600 rounded-lg hover:bg-red-700 transition duration-200 shadow-md transform hover:scale-105">
                            <FiLogOut className="mr-2" />
                            Logout
                        </button>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default Sidebar;
