import React, { useState } from 'react';
import { IoSend } from 'react-icons/io5';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setMessages } from '../../redux/messageSlice';
import { BASE_URL } from '../..';

const SendInput = () => {
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();
    const { selectedUser } = useSelector(store => store.user);
    const { messages } = useSelector(store => store.message);

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (!message.trim()) return; // Prevent sending empty messages
        try {
            const res = await axios.post(`${BASE_URL}/api/v1/message/send/${selectedUser?._id}`, { message }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
            dispatch(setMessages([...messages, res?.data?.newMessage]));
            setMessage(""); // Clear input field after sending
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={onSubmitHandler} className="my-3 px-4 sm:px-6 lg:px-64">
            <div className="w-full relative">
                <input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    placeholder="Send a message..."
                    className="w-full p-3 text-sm h-12 bg-baseBackground rounded-md border border-gray-100 text-titleColor  placeholder:text-textColor focus:outline-none focus:ring focus:ring-primary focus:border-primary transition duration-200"
                />
                <button
                    type="submit"
                    className={`absolute inset-y-0 right-0 flex items-center p-2 text-textColor bg- rounded-lg hover:bg-opacity-80 transition duration-200 ${
                        message.trim() ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
                    }`}
                    disabled={!message.trim()} // Disable button if input is empty
                >
                    <IoSend size={20} />
                </button>
            </div>
            <p className="mt-2 text-xs text-background text-center">
                Press Enter to send your message
            </p>
        </form>
    );
};

export default SendInput;
