import React from 'react';
import { FaUserCircle } from 'react-icons/fa'; // User icon for online status
import { AiOutlineArrowLeft } from 'react-icons/ai'; // Back arrow icon
import SendInput from './SendInput';
import Messages from './Messages';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Sidebar from './Sidebar';
import Navbar from '../Navbar';

const MessageContainer = () => {
    const navigate = useNavigate(); // Initialize navigate
    const { selectedUser, authUser, onlineUsers } = useSelector(store => store.user);
    const isOnline = onlineUsers?.includes(selectedUser?._id);

    return (
       <>
       {/* <Navbar/> */}
       <Sidebar />
         <div className="flex flex-col w-full h-screen rounded-lg shadow-lg border bg-baseBackground dark:bg-darkBackground dark:border-gray-700">
            {selectedUser !== null ? (
                <div className="flex flex-col flex-1">
                    {/* User Info */}
                    <div className="flex items-center justify-end gap-4 bg-background dark:bg-darkBackground p-3 rounded-lg mb-4 shadow-md">
                        <div className="flex flex-col items-end">
                            <p className="text-lg font-semibold text-textColor dark:text-lightGrayBackground truncate">
                                {selectedUser?.fullName}
                            </p>
                            <p className="text-sm text-gray-400 dark:text-gray-500">
                                {isOnline ? 'Online' : 'Offline'}
                            </p>
                        </div>
                        <div className="relative">
                            <div className={`w-12 h-12 rounded-full border-2 ${isOnline ? 'border-green-500' : 'border-gray-500'} overflow-hidden`}>
                                <img 
                                    src={selectedUser?.profilePhoto} 
                                    alt="user-profile" 
                                    className="w-full h-full object-cover" 
                                />
                            </div>
                            {isOnline && (
                                <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></div>
                            )}
                        </div>
                    </div>

                    {/* Scrollable Messages Container */}
                    <div className="flex-1 overflow-y-auto max-h-full lg:max-h-[75vh] md:max-h-[75vh] scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
                        <Messages />
                    </div>
                    
                    {/* Input Field for Sending Messages */}
                    <SendInput />
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center h-full text-center">
                    <FaUserCircle className="text-6xl text-gray-300 dark:text-gray-600 mb-4" />
                    <h1 className="text-3xl sm:text-4xl text-textColor dark:text-lightGrayBackground font-bold">
                        Hi, {authUser?.fullName}
                    </h1>
                    <h2 className="text-xl sm:text-2xl text-gray-400 dark:text-gray-500">
                        Let's start a conversation
                    </h2>
                </div>
            )}
        </div>
       </>
    );
}

export default MessageContainer;
