import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import signup from "./signup.png"
import toast from 'react-hot-toast';
import { BASE_URL } from '../..';
import { FaUser, FaLock, FaMale, FaFemale, FaSignInAlt, FaSpinner,FaUserAlt,FaImage,FaRegCommentDots,FaUserCircle} from 'react-icons/fa';

const Signup = () => {
  const [user, setUser] = useState({
    fullName: '',
    username: '',
    password: '',
    confirmPassword: '',
    gender: '',
    bio: '',
    website: '',
    profileImg: null,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setUser({ ...user, profileImg: e.target.files[0] });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (user.password !== user.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    const formData = new FormData();
    formData.append('fullName', user.fullName);
    formData.append('username', user.username);
    formData.append('password', user.password);
    formData.append('confirmPassword', user.confirmPassword);
    formData.append('gender', user.gender);
    formData.append('bio', user.bio);
    formData.append('website', user.website);
    if (user.profileImg) {
      formData.append('profileImg', user.profileImg);
    }

    setLoading(true);

    try {
      const res = await axios.post(`${BASE_URL}/api/v1/user/register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      if (res.data.success) {
        navigate('/login');
        toast.success(res.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred!");
      console.error(error);
    } finally {
      setLoading(false);
      setUser({
        fullName: '',
        username: '',
        password: '',
        confirmPassword: '',
        gender: '',
        bio: '',
        website: '',
        profileImg: null,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-blue-100 py-8 px-4">
    <div className="max-w-3xl w-full bg-white shadow-lg rounded-2xl overflow-hidden">
      <div className="flex flex-col md:flex-row">
      
  
        {/* Right Section: Signup Form */}
        <div className="md:w-1/2 p-8 sm:p-10">
          <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
            Create Your Account
          </h2>
          <form
            onSubmit={onSubmitHandler}
            encType="multipart/form-data"
            className="space-y-6"
          >
            {/* Full Name */}
            <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Full Name
  </label>
  <div className="flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-inner border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500">
    <FaUserAlt className="text-gray-500 mr-3" /> {/* Icon for Full Name */}
    <input
      value={user.fullName}
      onChange={(e) => setUser({ ...user, fullName: e.target.value })}
      className="flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400"
      type="text"
      placeholder="John Doe"
      required
    />
  </div>
</div>

  
<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Username
  </label>
  <div className="flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-inner border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500">
    <FaUser className="text-gray-500 mr-3" /> {/* Icon for Username */}
    <input
      type="text"
      placeholder="johndoe123"
      value={user.username}
      onChange={(e) => setUser({ ...user, username: e.target.value })}
      className="flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400"
      required
    />
  </div>
</div>

  
{/* Password */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">
      Password
    </label>
    <div className="flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-inner border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500">
      <FaLock className="text-gray-500 mr-3" />
      <input
        type="password"
        placeholder="********"
        value={user.password}
        onChange={(e) => setUser({ ...user, password: e.target.value })}
        className="flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400"
        required
      />
    </div>
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">
      Confirm Password
    </label>
    <div className="flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-inner border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500">
      <FaLock className="text-gray-500 mr-3" />
      <input
        type="password"
        placeholder="********"
        value={user.confirmPassword}
        onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })}
        className="flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400"
        required
      />
    </div>
  </div>
</div>

{/* Gender */}
<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Gender
  </label>
  <div className="flex items-center space-x-6">
    <label className="flex items-center text-gray-700 cursor-pointer">
      <input
        type="radio"
        value="male"
        checked={user.gender === 'male'}
        onChange={() => setUser({ ...user, gender: 'male' })}
        className="mr-2 focus:ring-blue-500"
      />
      <FaMale className="text-blue-500 mr-2" />
      Male
    </label>
    <label className="flex items-center text-gray-700 cursor-pointer">
      <input
        type="radio"
        value="female"
        checked={user.gender === 'female'}
        onChange={() => setUser({ ...user, gender: 'female' })}
        className="mr-2 focus:ring-blue-500"
      />
      <FaFemale className="text-pink-500 mr-2" />
      Female
    </label>
  </div>
</div>

{/* Bio */}
<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Bio
  </label>
  <textarea
    placeholder="Tell us about yourself..."
    value={user.bio}
    onChange={(e) => setUser({ ...user, bio: e.target.value })}
    className="w-full h-24 px-4 py-2 rounded-lg bg-gray-100 shadow-inner border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none placeholder-gray-400"
  />
</div>

{/* website */}
<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Website (Optional)
  </label>
  <textarea
    placeholder="Enter your website URL here"
    value={user.website}
    onChange={(e) => setUser({ ...user, website: e.target.value })}
    className="w-full h-14 px-4 py-2 rounded-lg bg-gray-100 shadow-inner border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none placeholder-gray-400"
  />
</div>

{/* Profile Image */}
<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Profile Image
  </label>
  <div className="flex items-center bg-gray-100 rounded-lg px-3 py-2 shadow-inner border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500">
    <FaImage className="text-gray-500 mr-3" />
    <input
      type="file"
      accept="image/*"
      onChange={handleFileChange}
      className="flex-1 bg-transparent outline-none text-gray-600 file:bg-blue-500 file:text-white file:py-1 file:px-3 file:rounded-lg placeholder-gray-400"
    />
  </div>
</div>

  
            {/* Submit Button */}
            <button
              type="submit"
              disabled={loading}
              className={`w-full h-12 rounded-lg ${
                loading
                  ? 'bg-gray-400'
                  : 'bg-blue-500 hover:bg-blue-600 transition duration-200'
              } text-white font-semibold flex items-center justify-center`}
            >
              {loading ? <FaSpinner className="animate-spin mr-2" /> : <FaSignInAlt className="mr-2" />}
              {loading ? 'Creating Account...' : 'Signup'}
            </button>
          </form>
  
          <p className="text-center mt-4 text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="text-blue-500 hover:underline">
              Login
            </Link>
          </p>
        </div>

          {/* Left Section: Visual Appeal */}
          <div className="md:w-1/2 bg-blue-100 text-textColor flex flex-col items-center justify-center py-10 px-6">
          <h1 className="text-4xl font-bold">Welcome!</h1>
          <p className="mt-4 text-center">
            Join us today and explore a world of opportunities. Complete your
            signup in just a few steps!
          </p>
          <img
            src={signup}
            alt="Signup Illustration"
            className="mt-6 w-3/4"
          />
        </div>
      </div>
    </div>
  </div>
  
  
  );
};

export default Signup;
