import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        authUser: null,
        otherUsers: null,
        selectedUser: null,
        onlineUsers: null,
        userById: null, // User fetched by ID
    },
    reducers: {
        setAuthUser: (state, action) => {
            state.authUser = action.payload;
        },
        setOtherUsers: (state, action) => {
            state.otherUsers = action.payload;
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        setOnlineUsers: (state, action) => {
            state.onlineUsers = action.payload;
        },
        setUserById: (state, action) => {
            state.userById = action.payload; // Store user data fetched by ID
        },
        followUser: (state, action) => {
            if (state.userById) {
                state.userById.followers.push(action.payload); // Assume payload is the ID of the user being followed
            }
        },
        unfollowUser: (state, action) => {
            if (state.userById) {
                state.userById.followers = state.userById.followers.filter(id => id !== action.payload);
            }
        },
        updateUser: (state, action) => {
            const { id, updatedData } = action.payload;
            if (state.userById && state.userById.id === id) {
                state.userById = { ...state.userById, ...updatedData }; // Merge updated data into userById
            }
        },
        deleteUser: (state, action) => {
            const userId = action.payload;
            if (state.userById && state.userById.id === userId) {
                state.userById = null; // Set userById to null if it matches the deleted user ID
            }
            state.otherUsers = state.otherUsers.filter(user => user.id !== userId); // Remove the deleted user from otherUsers list
        }
    },
});

export const { 
    setAuthUser, 
    setOtherUsers, 
    setSelectedUser, 
    setOnlineUsers, 
    setUserById,
    followUser,
    unfollowUser,
    updateUser,
    deleteUser
} = userSlice.actions;

export default userSlice.reducer;
